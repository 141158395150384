<template>
  <div class="end" :style="containerStyle">
    <!-- <img src="@/assets/image/questionnaire/endImg.png" class="head" /> -->
    <div class="body">
      <p class="body-title">
        {{ info.closingTitle }}
        <!-- <span v-if="info.submitButton == '02'">Submitted successfully</span>
        <span v-else>提交成功！</span> -->
        </p>
      <p class="body-description">
        {{ info.closingContent }}
        <!-- 感谢您的反馈， -->
        <!-- <span v-if="info.submitButton == '02'">
          Your membership shall be approved by IAISP Executive Committee and activated after membership fee remittance completed.
        </span>
        <span v-else>
          您的问卷已经提交，
          <br />
          感谢您的参与！
        </span> -->
        <!-- 期待与您合作！ -->
      </p>
      <!-- <van-button
        class="button"
        color="linear-gradient(90deg, #FF891C, #FF5508)"
        @click="close"
        >确定
      </van-button> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.info = this.$store.getters.getQuestionnaireInfo
  },
  mounted() {
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goBack, false)
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false)
  },
  computed: {
    containerStyle() {
      if (this.info.backgroundImage) {
        return { backgroundImage: `url('${this.info.backgroundImage}')`, 'background-size': '100% auto' }
      } else {
        return { backgroundColor: this.info.backgroundColor }
      }
    },
  },
  methods: {
    goBack() {
      history.pushState(null, null, document.URL)
    },
    close() {
      this.$router.push('/questionnaire/start')
    },
  },
}
</script>
<style lang="less" scoped>
.end {
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  .backimg {
    width: 100%;
    position: absolute;
  }
  .head {
    width: 796px;
    position: absolute;
    top: 162px;
    left: 210px;
    z-index: 2;
  }
  .body {
    position: absolute;
    top: 606px;
    left: 56px;
    z-index: 1;
    width: 1172px;
    height: 2012px;
    height: 1500px;
    background: #ffffff;
    border-radius: 56px;
    text-align: center;
    &-title {
      font-size: 72px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #0e0e0e;
      margin-top: 484px;
      white-space:pre-wrap
    }
    &-description {
      margin-top: 80px;
      font-size: 56px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #b5b5b6;
      line-height: 72px;
      white-space:pre-wrap
    }
    .button {
      position: absolute;
      z-index: 3;
      width: 997px;
      top: 1692px;
      left: 87px;
      border-radius: 80px;
    }
  }
}
</style>
